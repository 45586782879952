import React from "react";
import './DownloadTitle.css';
import {Col} from "react-bootstrap";
import GeneralButton from "../../../CommonComponent/GeneralButton/GeneralButton";

const DownloadTitle = () => (
    <Col md={{span: 11, offset: 1}} xs={12} sm={12} className='card-spacer'>
        <div className='large-page-title red-font-color label-align guide-line-height' style={{'fontWeight': 900}}>
            Employee Wellness <br/> Programs Guidelines
        </div>
        <div className='card-title grey-font-color label-align title-line-height'>
            Learn how to start a employee wellness <br/> program that employees will want to join
        </div>
        <div className='content-spacer column-container'>
            <div className='m-md-0 auto-margin'>
                <GeneralButton label={'Free Download'}/>
            </div>
        </div>
    </Col>
);

export default DownloadTitle;
