import React, {useState} from "react";
import './TrustedBy.css';
import {Col, Row} from "react-bootstrap";
import orientLogo from '../../../../assets/orient-logo.png';
import gtcLogo from '../../../../assets/gtc-logo.png';
import ninjaLogo from '../../../../assets/ninja-express-logo.png';
import mandiriLogo from '../../../../assets/mandiri-logo.png';
import gaiaLogo from '../../../../assets/gaia-cosmo-logo.png';
import galvaLogo from '../../../../assets/galva-logo.png';
import ukridaLogo from '../../../../assets/ukrida-logo.svg';
import djarumFoundationLogo from '../../../../assets/bakti-pendidikan.png';
import gaiaBandung from '../../../../assets/gaia-bandung.png';
import dunamisLogo from '../../../../assets/dunamis.png';
import colliersLogo from '../../../../assets/lp/colliers-logo.png';
import bostonLogo from '../../../../assets/lp/Boston.png';
import kpi from '../../../../assets/kpi.png';
import amartha from '../../../../assets/logo/amartha-logo.png';
import apkiLogo from '../../../../assets/logo/apki-logo.png';
import bmjLogo from '../../../../assets/logo/bmj-logo.png';
import mindIdLogo from '../../../../assets/logo/mind-id.svg';
import smfLogo from '../../../../assets/logo/smf-logo.png';
import kompasLogo from '../../../../assets/logo/kompas-logo.svg';
import benihBaikLogo from '../../../../assets/logo/benihbaik.png';
import growthCenterLogo from '../../../../assets/logo/growth-center.png';
import kgxLogo from '../../../../assets/logo/kgx.png';
import semaraLogo from '../../../../assets/logo/sembara.png';





const TrustedBy = ({}) => {
    const partners = [djarumFoundationLogo,mandiriLogo,kpi,kompasLogo,amartha,dunamisLogo,mindIdLogo, benihBaikLogo,bostonLogo,ninjaLogo,bmjLogo,apkiLogo, kgxLogo,colliersLogo, growthCenterLogo,gtcLogo,orientLogo,galvaLogo,ukridaLogo,smfLogo,gaiaLogo,gaiaBandung, semaraLogo];
    return (
        <Col md={10} sm={12} xs={12} className='auto-margin padding-card'>
            <Row className='flex flex-row align-items-center justify-content-center'>
                <Col className='border-bottom'/>
                <Col className='trustedBy-lbl p-2' md={3} sm={2} xs={5}>
                    LOVED & TRUSTED BY
                </Col>
                <Col className='border-bottom'/>
            </Row>
            <Row className='mt-4 ml-n2 mr-n2 d-flex flex-row flex-wrap justify-content-evenly' style={{gap: '3%'}}>
                {partners.map(value => <img className='partner-lp-img' src={value}/>)}
            </Row>
        </Col>
    )
}

export default TrustedBy;
